<template>
    <div class="container">
        
          <div class="aside-box">
            <div class="description-box">
              <div class="tech-desc">
                <el-image class="desc-img" src="http://www.nrb.net.cn/images/ic_1.png"></el-image>
                <div class="desc-box">
                  <div class="desc-title">帮助技术服务工程师</div>
                  <div class="desc-label">获得更多赚钱机会</div>
                </div>
              </div>
              <div class="consumer-desc">
                <el-image class="desc-img" src="http://www.nrb.net.cn/images/ic_2.png"></el-image>
                <div class="desc-box">
                  <div class="desc-title">帮助系统集成商</div>
                  <div class="desc-label">获得更多更专业的工程师资源</div>
                </div>

              </div>
            </div>
            <el-image class="aside-img" src="http://www.nrb.net.cn/images/bg_1.png"></el-image>
          </div>
          
   
            <el-form :model="form" status-icon :rules="rules" ref="form" class="login-form" @validate="validate"> 
                <div class="login-title">登录</div>
                 <el-form-item prop="mobile"><el-input placeholder="请输入手机号" v-model="form.mobile"></el-input></el-form-item>
                 <el-form-item prop="verify_code" v-if="showCheck">
                   <el-input class="sms-input" placeholder="请输入校验码" v-model="form.verify_code"></el-input>
                   <el-image class="get-sms get-code" :src="checkCodeImg" @click="getCode"></el-image> 
                 </el-form-item>
                 <el-form-item prop="sms">
                   <el-input class="sms-input" placeholder="请输入短信验证码" v-model="form.sms"></el-input>
                   <el-button class="get-sms" @click="getSms" :disabled="expire > 0">{{expire > 0 ? `${expire}s` : '获取验证码'}}</el-button>
                 </el-form-item>
                 
                 <div><el-button type="primary" @click="toLogin" @keyup.enter.native="toLogin" style="width: 340px; margin-bottom: 15px">登录</el-button></div>
            </el-form>
            
      
    </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
    name: "login",
    data() {
      return {
        showCheck: false,
        testData: {},
        disabled: false,
        expire: undefined,
        interval: undefined,
        v: 0,
        checkCodeImg: '/nrb_service/data/verify_code/get_code?v=0',
        form: {
          username: '',
          password: '',
          verify_code: ''
        },
        rules: {
            mobile: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' }
            ],
            sms: [
                { required: true, message: '请输入短信验证码', trigger: 'blur' },
                { min: 4, max: 4, message: '长度在4个字符', trigger: 'blur' }
            ],
            verify_code: [
                { required: true, message: '请输入校验码', trigger: 'blur' },
                { min: 4, max: 4, message: '长度在4个字符', trigger: 'blur' }
            ]
        }
      }

    },
    mounted() {
      this.getCode()
    },
    methods: {
      validate(res, data) {
        if (res === 'mobile' && data) {
          this.showCheck = true
        }
      },
      getCode() {
        this.v ++;
        this.checkCodeImg = `/nrb_service/data/verify_code/get_code?v=${this.v}`
      },
      getSms() {
        if (!this.form.mobile) {
          return;
        }
        this.axios({
          method: 'get',
          url: '/nrb_service/user/sendSms',
          params: this.form
        }).then(res => {
          if (res.data && res.data.code === 'A00000') {
            
            this.$message({
                type: 'success',
                message: "发送成功"
            });
            this.expire = 60
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.interval = setInterval(() => {
              this.expire = this.expire - 1
              if (this.expire <= 0) {
                clearInterval(this.interval)
              }
            }, 1000);
          }
        })
        
      },
      toLogin() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                this.login()
            } else {
                return false;
            }
        });
      },
      login() {
          this.axios({
            method: 'get',
            url: '/nrb_service/user/web_login',
            params: this.form
          }).then(res => {
            if (res.data && res.data.code === 'A00000') {
              this.$store.commit("user/SET_IS_LOGIN", true)
              this.$store.dispatch("user/getUserInfo")
              this.$router.push({name: 'project'})
            } else {
              this.$message({
                type: 'error',
                message: "登录失败，请输入正确的手机验证码"
            });
            }
          })
          
      },
    },
    
}
</script>

<style lang="scss" scoped>

.container {
  margin-top: 20px;
  background-color: white;
  height: 650px;
  display: flex;
  overflow: hidden;
}

.main {
  height: auto;
  overflow:  hidden !important;
}

.get-code {
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.login-form {
    padding: 10px 20px 30px 20px; 
    border: 1px solid #e7d7c0;
    width: 340px;
    margin: 10rem auto;

    .login-title {
      color: #f39801;
      font-size: 24px;
      text-align: center;
      margin: 10px auto 20px auto;
    }

    .sms-input {
      margin-right: 10px;
      width: 198px;
      vertical-align: top;
    }

    .get-sms {
      margin-left: 20px;
    }
}

.aside {
  width: auto !important;
  overflow:  hidden !important;
  
}

.aside-box {
  
  margin: 0 auto;
  display: flex;
}

.aside-img {
  
  margin: 100px auto;
}

.description-box {
  padding: 10rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  

  .tech-desc {
    display: flex;
    margin: 30px 0;
  }

  .consumer-desc {
    display: flex;
    margin: 30px 0;
  }

  .desc-img {
    margin-right: 20px;
    width: 67px;
    height: 67px;
  }
  
  .desc-title {
    color: #f39801;
    font-size: 26px;
    padding: 4px 0;
  }

  .desc-label {
    font-size: 18px;
    color: #9c9c9c;
    padding: 0 0 0 4px;
  }

  
}

@media screen and (max-width: 1500px) {
    .aside-img {
      display: none;
    }
}
</style>